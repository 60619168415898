@import 'src/assets/styles/media';

.welcome {
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  padding: 1rem 8rem 2rem 8rem;
  color: var(--color-fontBlack);

  .welcomeResetHolder {
    position: absolute;
    bottom: 30px;
    right: 50px;
    .welcomeResetButton {
      cursor: pointer;
      font-size: 1rem;
      font-weight: 600;
    }
  }
}

.welcome_container {
  width: 75rem;
}

.welcome__logo {
  margin: 0 auto 0.62rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mobile-xl {
    width: 100%;
    height: auto;
    margin: 0;
  }
}

.welcome_bi_logos {
  display: flex;
  width: 17rem;
  align-items: center;
  justify-content: flex-end;
  object-position: center;
  .welcome__img {
    max-width: 100%;
    min-width: 100%;
  }
  @include mobile-xl {
    width: 10.5rem;
    .welcome__img {
      height: 4rem;
      width: 6.5rem;
    }
  }
}

.welcome__img {
  width: 8rem;
  height: 3rem;
  object-position: center;
}
.welcome__img__emma{
  width: 156px;
  height: 26px;
}

.welcome__inner {
  margin-top: 1.5rem;
  text-align: center;
  background-color: var(--color-white);
  border-radius: 10px 10px 0px 0px;

  @include mobile-xl {
    height: calc(100% - 10.87rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }
}

.welcome__title {
  background: linear-gradient(180deg, #c2dec4 0%, #a3d7e9 100%);
  border-radius: 10px 10px 0px 0px;
  height: 10rem;
  justify-content: center;
  vertical-align: middle;
  padding: 1.75rem 0;
  word-spacing: 3px;
  position: relative;
  @include mobile-xl {
    margin-bottom: 1rem;
    height: 8rem;
  }
}

.welcome__subtitle {
  font-size: 1.94rem;
  font-weight: 800;
  line-height: 3.5rem;

  @include mobile-xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

.wc_title_bubble {
  position: absolute;
  left: 1rem;
  bottom: 0;
  height: 50%;
}

.welcome_inner_container {
  padding: 1.5rem;
  background: url('../../assets/nImages/paper_texture.svg');
  filter: drop-shadow(0px 4px 25px rgba(170, 154, 130, 0.8));
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0px 0px 10px 10px;
}

.welcome__inner_bottom {
  border: 5px solid var(--color-highlightGreen);
  padding: 1rem 2rem;
  font-size: 1.3rem;
  font-weight: 500;
  font-style: normal;
  line-height: 1.75rem;
  text-align: left;
}

.welcome__img {
  width: 8rem;
  height: 5rem;
  object-position: center;
  @include mobile-xl {
    width: 6rem;
    height: 4rem;
    padding-left: 4px;
  }
}

.welcome__event_info {
  font-weight: 700;
}

.welcome_case_info,
.welcome__region,
.welcome__date {
  text-align: left;
  font-weight: 400;
  font-size: 1.2rem;
}

.welcome__address {
  margin: 1.5rem 0;
  @include mobile-xl {
    margin-bottom: 1.2rem 0;
  }
}

.welcome__location {
  font-weight: 700;
}

.grayField {
  display: flex;
  justify-content: center;
  align-items: center;
}

.notFound {
  width: max-content;
  padding: 1.5rem;
  border-radius: 0.375rem;
  font-weight: 700;
  font-size: 1.125rem;
  color: var(--color-red);
}

.welcome_footer {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.event_details_holder {
  width: 100%;
  display: flex;
  flex-direction: row;
  .qr_code_holder {
    width: 30%;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 2rem 0.5rem;
    border-right: 1.5px solid #61667E;
    .qr_code_header{
      width: 100%;
    }
    .tiny_url {
      font-size: 1.1rem;
      font-weight: 700;
      line-height: 1.25rem;
      margin-top: 1.2rem;
    }
    .qr_code_header {
      justify-content: center;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 400;
      text-align: center;
      display: flex;
      margin-bottom: 1rem;
    }
  }
  .event_info_holder {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem;
  }
}
