@import '../../assets/styles/media';

.finalPage {
  width: 100%;
  height: calc(100% - 4rem);
  overflow-y: auto;
  display: flex;
  justify-content: center;
  padding: 1rem 8rem 2rem 8rem;
  .final_container {
    width: 75rem;
    display: flex;
    flex-direction: column;
    height: 100%;

    @include mobile-xl {
      width: 100%;
    }
    .final_bi_logos {
      display: flex;
      justify-content: space-evenly;
      width: 17rem;
      align-items: center;
      @include mobile-xl {
        width: 12rem;
      }
      .final__welcome_img{
        height: auto;
        width: 100% !important;
        @include tablet-xl   {
          width: 100% !important;
          height: auto !important;
        }
      }
    }
    .final__logo {
      display: flex;
      justify-content: space-between;
      height: 10rem;
      align-items: center;
      .final__img {
        width: 9rem;
      }
      .final__welcome_img {
        width: 10rem;
      }
      .final__welcome_img_emma{
        width: 156px;
        height: 26px;
      }
    }
    .finalPage__inner {
      display: flex;
      flex-direction: column;
      text-align: center;
      background-color: var(--color-white);
      border-radius: 1rem;
      background: url('../../assets/nImages/paper_texture.svg');
      background-repeat: no-repeat;
      background-size: cover;
      height: calc(100% - 8rem);
      filter: drop-shadow(0px 4px 25px rgba(170, 154, 130, 0.8));
      .finalPage_header {
        height: 3rem;
        width: 100%;
        content: '';
        display: flex;
        flex-direction: row;
        background: var(--color-linearGreen);
        box-sizing: border-box;
        border-radius: 1rem 1rem 0 0;
      }
      .finalPage_body {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 1rem;
        padding: 2rem;
        border: 3.5px solid var(--color-darkGreen);
        .finalPage__image {
          display: flex;
          justify-content: center;
          background: url('../../assets/nImages/group_stars.svg');
          background-repeat:round;
          background-size: cover;
          width: 100%;
          .finalPage__img {
            width: 8rem;
            height: 10rem;
            border: 4px solid var(--color-darkGreen);
            box-sizing: border-box;
            border-radius: 10px;
            object-fit: fill;
          }
        }
        .finalPage__info {
          display: flex;
          flex-direction: column;
          padding: 0 6rem 2rem 6rem;
          align-items: center;
          .finalPage__title {
            font-size: 1.94rem;
            padding: 1.75rem 0 1rem 0;
            font-weight: 700;
            word-spacing: 3px;
            color: var(--color-darkGreen);
          }

          .finalPage__subtitle {
            color: var(--color-fontBlack);
            font-size: 1.12rem;
            font-weight: 600;
            line-height: 1.5rem;
          }
        }
      }
    }
  }
}
